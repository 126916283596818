import { MenuItem } from './menu-item.model';

export function findInMenuItems(menuItems: MenuItem[], url: string, parent?: MenuItem | null): MenuItem | null {
  for (const item of menuItems) {
    if (item.path === url) {
      return {
        ...item,
        parent: parent ?? { path: `/${$localize.locale}` },
      };
    }

    if (item.subMenu) {
      const found: MenuItem | null = findInMenuItems(item.subMenu, url, item);

      if (found) {
        return found;
      }
    }
  }

  return null;
}

export function findMenuItemByIcon(menuItems: MenuItem[], icon: string, parent?: MenuItem | null): MenuItem | null {
  for (const item of menuItems) {
    if (item.icon === icon) {
      return {
        ...item,
        parent: parent ?? { path: `/${$localize.locale}` },
      };
    }

    if (item.subMenu) {
      const found: MenuItem | null = findMenuItemByIcon(item.subMenu, icon, item);

      if (found) {
        return found;
      }
    }
  }

  return null;
}
